<template>
  <div class="main-container">
    <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.3rem;display: inline-block;">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>返厂</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
    <div class="content-block">
      <am-detail :isBreadcrumb="false" :isMapShow="false" @getForm="getFormData"></am-detail>
      <div class="content-title separate-line">
        <p>返厂申请</p>
      </div>
      <div>
        <el-form ref="formRef" :model="formData" label-width="80px" :rules="rules">
<!--          <el-form-item label="返厂类型">-->
<!--            <el-radio-group v-model="formData.application_class">-->
<!--              <el-radio :label="1310" >维修</el-radio>-->
<!--              &lt;!&ndash;              <el-radio label="1311">报废</el-radio>&ndash;&gt;-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
          <el-form-item label="原因说明" prop="remarks">
            <el-input type="textarea" v-model="formData.remarks"></el-input>
          </el-form-item>
          <el-form-item label="相关附件" prop="fileList">
            <el-upload
                action="/api/upload_application_word/"
                list-type="text"
                accept=".doc,.docx"
                :headers="uploadHeaders"
                :show-file-list="true"
                :limit="5"
                :multiple="true"
                :file-list="fileList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request="handleUpload"
                :on-success="handleUploadSuccess"
            >
<!--              <i class="el-icon-plus"></i>-->
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" style="color: #BD3124;font-size: 13px">只能上传.doc或.docx文件</div>
            </el-upload>
<!--            <span style="color: #BD3124;font-size: 13px">注：请提交图片格式文件</span>-->
<!--            <el-dialog :visible.sync="fileDialogVisible">-->
<!--              <img width="100%" :src="dialogImageUrl" alt="">-->
<!--            </el-dialog>-->
          </el-form-item>
        </el-form>

          <div class="content-title separate-line" style="margin-bottom: 16px"  v-if="suggestList.length >0">
            <p >流转意见</p>
          </div>
          <div class="renturn-suggest">
          <ul>
            <li v-for="item in suggestList" class="item-block">
              <div class="left-column">
                <p class="left" style="color: #2D74B6">{{item.send_user_name}}</p>
                <p class="left">{{item.send_user_org}}</p>
              </div>
              <div class="right-column">
                <p class="right">接收人：{{item.receive_user}}</p>
                <p class="right" v-if="item.opt_agreeable_unique == '驳回'">驳回意见：{{item.remarks}}</p>
                <p class="right">{{item.create_time}}【{{item.opt_agreeable_unique}}】</p>
              </div>
            </li>
          </ul>
        </div>

        <div class="footer">
          <el-button type="primary" size="small" @click="submit(1)">提交</el-button>
          <el-button size="small" v-if="$route.query.application_status != 1303" @click="submit(0)">暂存</el-button >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import AssetsDetail from "@/views/AssetManagement/components/assetsDetail.vue";
import AmDetail from "@/views/AssetManagement/amDetail.vue";
import {applyDetail, getReturnApply, returnApply, uploadFile} from "@/api/assetMgt";
import {getFileName, put} from "@/assets/js/oss";
import {Message} from "element-ui";
import TJDetailTitle from "@/components/TJDetailTitle.vue";

export default {
  name: 'applyReturnFactory',  // 申请返厂
  components: {TJDetailTitle, AssetsDetail,AmDetail},
   data(){
     return{
       action: `${process.env.VUE_APP_BASE_API}/api/upload_ins_file/`,
       // fileDialogVisible:false,
       dialogImageUrl: '',
       dialogVisible: false,
       nowFileNum: 0,
       uploadFilesLength: 1, //待传图片数
       platform_unique: 0,
       fileList: [],
       suggestList: [],
       formData:{
         note_id: null,
         instrument_id: this.$route.query.instrument_id,
         // application_class: 1310,
         remarks: '',
         file_urls: [],
       },
       token: localStorage.getItem('token') ||localStorage.getItem('vue_token'),
       uploadHeaders: {
         Authorization: `Bearer ${this.token}` // 假设你的后端需要这样的 Authorization 头部格式
       },
       rules:{
         remarks: [
           {required: true, message: '请输入原因说明', trigger: 'blur'},
           {min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur'}
       ],
         fileList: [
           {required: true, message: '请上传相关附件', trigger: 'change'},
         ]
       }
     }
   },
  created() {
    if(this.$route.query.instrument_id){
      this.getDetail()
    }
  },
  methods:{
    getDetail(){
      let isSubmit
      if(this.$route.query.application_status == 1303){
        isSubmit = 1
      }else{
        isSubmit = 0
      }
      applyDetail({instrument_id:this.$route.query.instrument_id,is_submitted: isSubmit}).then(res =>{
        this.formData = Object.assign(this.formData,res.data.note_info)
        // this.formData.note_id = res.data.note_info.note_id
        // this.formData.application_class  = 1310
        // this.suggestList = res.data.process_info
        let process_info = res.data.process_info
        let approval_info = res.data.approval_info
        //
        if(process_info && approval_info && (process_info.length == approval_info.length)){
          // 融合数组
          const fusedArray = [];
          for (let i = 0; i < process_info.length; i++) {
            // 将两个对象融合成一个新的对象，使用展开操作符或者手动赋值
            const fusedObject = {
              ...process_info[i], // 融合 process_info 的对象
              ...approval_info[i] // 融合 approval_info 的对象
              // 如果有重名键，后面的对象会覆盖前面的对象中的值
            };
            fusedArray.push(fusedObject);
          }
          this.suggestList = fusedArray
        }
       //
        this.formData.attachments.forEach(attachment => {
          if (attachment.hasOwnProperty('attachment_url') && attachment.attachment_url) {
            // attachment.url = attachment.attachment_url;
            this.fileList.push({url:attachment.attachment_url,name:attachment.attachment_url.split('/')[attachment.attachment_url.split('/').length - 1]})
          }
        })
        if(this.fileList && this.fileList.length > 0){
          this.formData.file_urls = this.fileList.map(item => item.url)
        }
        let currentUserId = JSON.parse(localStorage.getItem("userInfo")).user_id

        if(this.suggestList.length > 0 && this.suggestList[0].receive_uid == currentUserId){
          this.isApprove = true
        }
      })
    },
    getFormData(val){
      this.platform_unique = val.platform_unique
    },
    submit(type){
      if(type && this.formData.remarks === ''){
        return this.$message.error('请输入原因说明')
      }
      this.formData.is_submitted = type
      let fileListTemp = this.fileList.map(item =>  item.url )
      this.formData.file_urls = fileListTemp
      const loading = this.$loading({
        lock: true,
        text: type ? '提交中...' :'保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      returnApply(this.formData).then(res =>{
        if(res.code === 200){
          this.$message.success(type ?'提交成功' : '保存成功')
          loading.close();
          this.$router.push('/assetMgt/list')
        }
      }).catch(res =>{
        loading.close();
      })
    },
    handleUpload(file) {
      const formData = new FormData();
      formData.append('files', file.file); // 注意：在某些 Element UI 版本中，可能需要使用 file.file 而不是 file.raw
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}` // 假设 token 已经在 data 或 computed 中定义
        }
      };
      // ${baseUrl}/api/upload_asset_file/   上传图片
      let baseUrl= process.env.VUE_APP_BASE_API
      axios.post(`${baseUrl}/api/upload_application_word/`, formData, config)
          .then(data => {
            // 处理响应
            let resUrl =  data.data.data.urls[0]
            this.fileList.push({url: resUrl, name: file.file.name, uid: file.file.uid})
          })
          .catch(error => {
            // 处理错误
          });
      // 获取文件的uid
      // let suffix = getFileName(option.file.name) // 获取文件的后缀名
      // //                          仪器序列号                      仪器型号                返厂类型-维修                                            标号
      // let objName = `/media/report/${this.$route.query.instrument_id}/${this.formData.application_class}/${option.file.uid}${suffix}`
      // // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      // put(objName, option.file).then((res) => {
      //   // if (this.nowFileNum === this.uploadFilesLength) {
      //     Message.success('上传成功')
      //     this.nowFileNum = 0
      //     let url = 'https://image.51ipoct.com' + objName
      //     // 注意：为了解决图片闪动问题，必须将uid赋值给图片，否则图片会闪动
      //     this.fileList.push({url: url, name: option.file.name, uid: option.file.uid})
      //     this.formData.file_urls.push(url)
      //     this.dialogVisible = false
      //     // this.loading.close()
      //   // }
      //   // 上传成功之后，转换真实的地址
      // })
    },
    handleUploadSuccess(){
      // this.fileList = this.fileList.concat({url: url, name: option.file.name})
    },
    goBack(){
      this.$router.push({ path: '/assetMgt/list' })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      // this.fileListTemp = fileList.map(item=>item.url)
      this.formData.file_urls = fileList.map(item=>item.url)
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    //   this.fileDialogVisible = true
    // }
  }
}
</script>


<style scoped lang="scss">
@import '@/assets/css/asset';
.footer{
  text-align: center;
}
::v-deep .el-descriptions-item__container{
  color: #000;
}
//特殊详情处理
.zone1{
  padding: 0;
}
::v-deep .el-tabs__header{
  padding-left: 10px;
}
</style>